export const debounce = (func, wait) => {
	let timeout;
	return function (...args) {
		const context = this;
		clearTimeout(timeout);
		timeout = setTimeout(() => {
			func.apply(context, args);
		}, wait);
	};
};

export const deferAsync = async (func, deferFunc) => {
	const func1Result = await func();
	await deferFunc();
	return func1Result;
};
